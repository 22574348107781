/* global config */

'use strict';

angular.module('managerApp').service('permissions', function (http) {
    const url = config.apiURL + 'users/permissions';
    this.getPermissions = () => {
        return new Promise((resolve, reject) => {
            http.get(url).then((res) => {
                resolve(res.data);
            }, (err) => {                
                reject(err);                
            });
        });
    };
});


